<template lang="html">
  <header class="Header">
    <div class="HeaderLogo">
      <Logo :color="pageColor" />
      <Trigger :color="pageColor" title="Open Menu" />
    </div>
    <Slideshow :color="pageColor" />
    <div class="note" v-if="pageData.id == 1">
      <h2>2025 Anniversary Year</h2>
      <p>
        2025 marks the 90th anniversary of Christo and Jeanne-Claude's birth, as
        well as key anniversaries of three of their most iconic projects.
      </p>

      <button
        v-for="(item, index) in navi"
        class="l"
        @click="navigate(index)"
        v-bind:key="index"
      >
        {{ item.title }}
      </button>
    </div>
  </header>
</template>

<script>
import Slideshow from "@/components/Slideshow/SlideshowSlideshow.vue";
import Logo from "@/components/Elements/ElLogo.vue";
import Trigger from "@/components/Elements/ElNavigationTrigger.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Slideshow,
    Logo,
    Trigger,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showNavi: false,
      navi: [
        {
          title: "More Information",
          path: "/2025",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["pageData"]),
    pageColor() {
      if (this.windowWidth > this.windowHeight) {
        return this.pageData.is_color;
      } else return this.pageData.is_color_mobile;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    navigate(i) {
      if (this.$route.path == this.navi[i].path) {
        this.scrollDown();
      } else {
        this.$router.push({ path: this.navi[i].path });
      }
    },
    scrollDown() {
      let newPos;
      if (window.innerWidth > 600) {
        newPos = window.innerHeight - 30;
      } else {
        newPos = window.innerHeight;
      }
      window.scrollTo({
        top: newPos,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css" scoped>
/* HEADER */
.Header {
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  margin: 0;
}

.HeaderLogo {
  position: absolute;
  z-index: 999999999;
  top: 24px;
  left: 24px;
  display: grid;
  width: calc(100% - 48px);
  grid-template-columns: auto min-content;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.Trigger {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (min-width: 768px) {
  .Header {
    height: calc(100vh - 38px);
  }
}
</style>
